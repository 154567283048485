import { ContactForm } from "./components/contact-form/contact-form";

document.addEventListener('DOMContentLoaded', function() {

	if ( document.querySelectorAll('.video-player').length > 0 ) {
		import('./components/video-player/video-player').then( vp => {
			document.querySelectorAll('.video-player').forEach( (el: HTMLElement) => new vp.VideoPlayer(el) );
		});
	}

	if ( document.querySelectorAll('.contact-form form').length > 0 ) {
		document.querySelectorAll('.contact-form form')
			.forEach( (cf: HTMLElement) => {
				new ContactForm(cf);
		});
	}

	if ( document.querySelector('.swiper') ) {
		import('./components/swiper/swiper').then( sw => {
			new sw.Swiper( document.querySelector('.swiper') );
		});
	}

	import('./pages/page').then(c => new c.Page() );

}, false);

