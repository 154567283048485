import { FetchHeaders } from '../../helpers/fetch-headers';

// @ts-ignore
import * as Pristine from 'pristinejs';

// @ts-ignore
declare var grecaptcha;

// @ts-ignore
declare var dataLayer;

import { gsap } from 'gsap';


export class ContactForm {

	trigger: HTMLButtonElement;

	pristine: any;

	static pristineConfig = {
		classTo: 'field',
		errorClass: 'error',
		successClass: 'success',
		errorTextParent: 'field',
		errorTextTag: 'p',
		errorTextClass: 'error'
	};

	wrapper: HTMLElement;
	contactForm: HTMLFormElement;
	cta: HTMLButtonElement;
	contactSubmitting: HTMLElement;
	contactSuccess: HTMLElement;
	contactError: HTMLElement;
	fields: NodeListOf<HTMLElement>;

	constructor(cf: HTMLElement) {

		this.wrapper        = cf.closest('.contact-form');
		this.contactForm    = <HTMLFormElement>cf;

		this.cta    = this.contactForm.querySelector('button[type="submit"]');
		this.fields = this.contactForm.querySelectorAll('input,select,textarea');

		this.contactSubmitting = this.wrapper.querySelector('.submitting');
		this.contactSuccess    = this.wrapper.querySelector('.success-message');
		this.contactError      = this.wrapper.querySelector('.error-message');


		this.pristine = new Pristine(this.contactForm, ContactForm.pristineConfig);


		this.cta.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();

			const valid = this.pristine.validate();

			if (valid) {
				const data: any = {};

				this.fields.forEach((el: HTMLInputElement | HTMLSelectElement) => {
					if (el.type == 'radio' || el.type == 'checkbox') {
						// @ts-ignore
						if (el.checked) {
							data[el.getAttribute('name')] = el.value;
						}
					} else {
						data[el.getAttribute('name')] = el.value;
					}
				});

				this.setFormSubmitting();

				const siteKey = this.cta.dataset.sitekey;
				const action  = this.contactForm.getAttribute('action');

				grecaptcha.ready(() => {
					grecaptcha
						.execute(siteKey, {action: 'submit'})
						.then((token: string) => {
							data.token = token;

							// @ts-ignore
							if (window.datalayer) {
								dataLayer.push({ event: 'form_submit_contact1' });
							}

							ContactForm.performAsyncRequest(action, data, (data: any) => {
								if (data.result == 'ok') {
									this.showSuccessMessage();
								} else {
									this.showErrorMessage();
								}
							});
						});
				});
			}
		});

	}


	public setFormSubmitting() {
		this.contactSubmitting.style.zIndex = '10';
		this.cta.setAttribute('disabled', '');

		gsap.to(this.contactSubmitting, {
			opacity: .8,
			duration: .3
		});
	}


	public setFormDesubmitting() {
		gsap.to(this.contactSubmitting, {
			opacity: 0,
			duration: .3,
			onComplete: () => this.contactSubmitting.style.zIndex = '-1'
		});
	}


	public showHideMessage( block: HTMLElement ) {
		block.style.zIndex = '10';

		gsap.to(block, {
			opacity: .8,
			duration: .3,
			onComplete: () => {

				gsap.to(block, {
					opacity: 0,
					duration: .3,
					delay: 5,
					onComplete: () => {
						block.style.zIndex = '-1';
						this.cta.removeAttribute('disabled');
					}
				});

			}
		});
	}


	public showSuccessMessage() {
		this.setFormDesubmitting();
		this.showHideMessage( this.contactSuccess );
		this.contactForm.reset();
	}


	public showErrorMessage() {
		this.setFormDesubmitting();
		this.showHideMessage( this.contactError );
	}


	public static performAsyncRequest(
		url: string,
		data: any,
		callback: Function
	) {
		fetch(url, {
			headers: FetchHeaders.prepare(),
			method: 'post',
			credentials: 'same-origin',
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => { callback(data); })
			.catch((error) => { callback(data); });
	}

}

